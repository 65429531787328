import React from "react";

export default function Credits() {
  return (
    <div className="mt-8 mx-2 font-sans-caps lowercase">
      <h2 className="text-lg border-b border-black">Credits</h2>
      <table className="mt-2 table-auto text-sm">
        <tbody>
          <tr>
            <td className="pr-3">Justin Austin</td>
            <td className="">Development &amp; Design</td>
          </tr>
          <tr>
            <td className="pr-3">Reed Maltzman</td>
            <td className="">Concept &amp; Design</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
