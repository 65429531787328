import React from "react";
import Button, { buttonSizeClasses, ButtonType } from "../Button";
import InstructionsGridTen from "./InstructionsGridTen";

type Props = {
  onPrevious: () => void;
  onNext: () => void;
};

export default function ThirdInstructions({ onPrevious, onNext }: Props) {
  return (
    <>
      <p>
        Again, we have <span className="font-sans-caps">IMPOSSIBLE</span>, just
        displayed a different way and in a different direction.
      </p>
      <InstructionsGridTen
        word="BISSOPMIEL"
        startingIndex={7}
        clockwise={false}
      />
      <div className="flex justify-between">
        <Button content={ButtonType.Previous} onClick={onPrevious} />
        <span>3/4</span>
        <Button content={ButtonType.Next} onClick={onNext} />
      </div>
    </>
  );
}
