import React from "react";
import Button, { ButtonType } from "../Button";
import InstructionsGridTen from "./InstructionsGridTen";

type Props = {
  onPrevious: () => void;
  onNext: () => void;
};

export default function SecondInstructions({ onPrevious, onNext }: Props) {
  return (
    <>
      <p>
        This word is also <span className="font-sans-caps">IMPOSSIBLE</span>,
        just displayed a different way.
      </p>
      <InstructionsGridTen
        word="IBLEIMPOSS"
        startingIndex={4}
        clockwise={true}
      />
      <div className="flex justify-between">
        <Button content={ButtonType.Previous} onClick={onPrevious} />
        <span>2/4</span>
        <Button content={ButtonType.Next} onClick={onNext} />
      </div>
    </>
  );
}
