import Feedback from "../Feedback/Feedback";
import { useGameState } from "../GameStateContext";
import GridEight from "./GridEight";
import GridEleven from "./GridEleven";
import GridFive from "./GridFive";
import GridFour from "./GridFour";
import GridNine from "./GridNine";
import GridSeven from "./GridSeven";
import GridSix from "./GridSix";
import GridTen from "./GridTen";
import GridTwelve from "./GridTwelve";
import Square from "./Square";

const wordToLetterArray = (word: string) => {
  if (word.length === 4) {
    return [
      null,
      null,
      word.charAt(0),
      word.charAt(1),
      null,
      null,
      null,
      word.charAt(3),
      word.charAt(2),
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ];
  } else if (word.length === 5) {
    return [
      word.charAt(0),
      word.charAt(1),
      word.charAt(4),
      null,
      word.charAt(2),
      null,
      null,
      null,
      word.charAt(3),
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ];
  } else if (word.length === 6) {
    return [
      null,
      null,
      word.charAt(0),
      word.charAt(1),
      word.charAt(2),
      null,
      null,
      word.charAt(5),
      word.charAt(4),
      word.charAt(3),
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ];
  } else if (word.length === 7) {
    return [
      word.charAt(0),
      word.charAt(1),
      word.charAt(6),
      null,
      word.charAt(2),
      null,
      null,
      word.charAt(5),
      null,
      word.charAt(3),
      null,
      null,
      null,
      word.charAt(4),
      null,
      null,
      null,
    ];
  } else if (word.length === 8) {
    return [
      null,
      null,
      word.charAt(0),
      word.charAt(1),
      word.charAt(2),
      null,
      null,
      word.charAt(7),
      null,
      word.charAt(3),
      null,
      null,
      word.charAt(6),
      word.charAt(5),
      word.charAt(4),
      null,
      null,
    ];
  } else if (word.length === 9) {
    return [
      word.charAt(0),
      word.charAt(1),
      word.charAt(8),
      null,
      word.charAt(2),
      null,
      null,
      word.charAt(7),
      null,
      word.charAt(3),
      null,
      null,
      word.charAt(6),
      word.charAt(5),
      word.charAt(4),
      null,
      null,
    ];
  } else if (word.length === 10) {
    return [
      word.charAt(0),
      word.charAt(1),
      word.charAt(9),
      null,
      word.charAt(2),
      null,
      null,
      word.charAt(8),
      null,
      word.charAt(3),
      null,
      null,
      word.charAt(7),
      null,
      word.charAt(4),
      word.charAt(6),
      word.charAt(5),
    ];
  } else if (word.length === 11) {
    return [
      word.charAt(0),
      word.charAt(1),
      word.charAt(10),
      null,
      word.charAt(2),
      word.charAt(9),
      word.charAt(3),
      null,
      null,
      null,
      word.charAt(8),
      word.charAt(4),
      word.charAt(7),
      word.charAt(6),
      word.charAt(5),
      null,
      null,
    ];
  } else if (word.length === 12) {
    return [
      word.charAt(0),
      word.charAt(1),
      word.charAt(11),
      null,
      word.charAt(2),
      word.charAt(10),
      word.charAt(3),
      null,
      null,
      null,
      word.charAt(9),
      word.charAt(4),
      word.charAt(8),
      null,
      word.charAt(5),
      word.charAt(7),
      word.charAt(6),
    ];
  } else if (word.length === 0) {
    let arr = new Array(17).fill(null);
    arr[8] = "✅";
    return arr;
  } else {
    throw `Word ${word} is not a valid size`;
  }
};

export default function Board() {
  const gameState = useGameState();
  const word =
    gameState.scrambled_original_word_pairs[gameState.currentWordIndex]
      .scrambled;

  let grid;
  switch (word.length) {
    case 4:
      grid = <GridFour word={word} />;
      break;
    case 5:
      grid = <GridFive word={word} />;
      break;
    case 6:
      grid = <GridSix word={word} />;
      break;
    case 7:
      grid = <GridSeven word={word} />;
      break;
    case 8:
      grid = <GridEight word={word} />;
      break;
    case 9:
      grid = <GridNine word={word} />;
      break;
    case 10:
      grid = <GridTen word={word} />;
      break;
    case 11:
      grid = <GridEleven word={word} />;
      break;
    case 12:
      grid = <GridTwelve word={word} />;
      break;
  }
  return (
    <div className="grow relative my-auto">
      <div className="flex align-center h-full">{grid}</div>
      <Feedback />
    </div>
  );
}
