import React from "react";

type Props = {
  header: string;
  data: string;
  isBold: boolean;
};

export default function StatisticsRow({ header, data, isBold }: Props) {
  const baseHeaderClassNames =
    "font-sans-caps text-right pr-2 w-3/5 border-r border-black pb-1";
  const headerClassNames = isBold
    ? `${baseHeaderClassNames} font-bold`
    : `${baseHeaderClassNames} font-normal`;

  return (
    <tr className="flex justify-between">
      <th className={headerClassNames}>{header}</th>
      <td className="w-1/3">{data}</td>
    </tr>
  );
}
