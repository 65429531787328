import React from "react";
import Square, { BlankSquare } from "./Square";

export default function GridNine({ word }: { word: string }) {
  if (word.length !== 9) {
    throw new Error("GridNine only works with words of length 9");
  }
  return (
    <div className="w-fit m-auto">
      <div className="flex justify-center">
        <Square word={word} index={0} />
        <Square word={word} index={1} />
      </div>
      <div className="flex justify-center">
        <Square word={word} index={8} />
        <BlankSquare />
        <Square word={word} index={2} />
      </div>
      <div className="flex justify-center">
        <Square word={word} index={7} />
        <BlankSquare />
        <Square word={word} index={3} />
      </div>
      <div className="flex justify-center">
        <Square word={word} index={6} />
        <Square word={word} index={5} />
        <Square word={word} index={4} />
      </div>
    </div>
  );
}
