import { State, useGameState } from "./GameStateContext";
import SingleScoreIndicator from "./SingleScoreIndicator";
import React from "react";

export default function ScoreBar() {
  const gameState = useGameState();

  return (
    <ul className="list-none ml-4 my-2">
      {gameState.answers.map((answer, i) => (
        <SingleScoreIndicator
          key={i}
          answer={answer}
          isCurrentWord={
            i === gameState.currentWordIndex &&
            gameState.currentState === State.Pending
          }
        />
      ))}
    </ul>
  );
}
