import React from "react";

type FeedbackInstanceProps = {
  content: string;
  colorClass: string;
};

export default function FeedbackInstance({
  content,
  colorClass,
}: FeedbackInstanceProps) {
  return (
    <div className={`rounded-full text-center font-sans-caps ${colorClass}`}>
      {content.toUpperCase()}
    </div>
  );
}
