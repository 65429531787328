import Button, { ButtonType } from "./Button";
import React from "react";

type ModalProps = {
  children: React.ReactNode;
  title: string;
  onExit: () => void;
  showClose: boolean;
};

export default function Modal({
  children,
  title,
  onExit,
  showClose,
}: ModalProps) {
  return (
    <div className="fixed z-10 top-0 inset-x-0 h-full max-w-md mx-auto bg-white overflow-y-scroll">
      <div className="mx-auto mt-8 p-2 w-11/12 border border-black rounded-lg bg-white overflow-y-scroll" style={{maxHeight: "90%"}}>
        <div className="flex justify-between">
          {showClose && <span className="h-8 w-8" />}
          <h2 className="text-center grow text-lg lowercase font-sans-caps">
            {title}
          </h2>
          {showClose && <Button content={ButtonType.Close} onClick={onExit} />}
        </div>
        {children}
      </div>
    </div>
  );
}
