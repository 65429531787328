import React from "react";
import { Answer } from "../GameStateContext";
import SingleScoreIndicator from "../SingleScoreIndicator";

export default function WordsDisplay({
  words,
  answers,
}: {
  words: string[];
  answers: Answer[];
}) {
  return (
    <>
      <div className="h-8 w-3/5 pr-2 border-r border-black" />
      <ol>
        {words.map((word, i) => (
          <li key={i} className="flex justify-between">
            <span className="font-sans-caps text-lg text-right pr-2 w-3/5 border-r border-black">
              {word.toLowerCase()}
            </span>
            <span className="w-1/3">
              <SingleScoreIndicator
                answer={answers[i]}
                isCurrentWord={false}
                isWithinList={false}
                verticalAlign={true}
              />
            </span>
          </li>
        ))}
      </ol>
    </>
  );
}
