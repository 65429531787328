import React from "react";
import Button, { ButtonType } from "./Button";
import { useGameStateDispatch } from "./GameStateContext";

export default function ButtonRow() {
  const dispatch = useGameStateDispatch();

  return (
    <div className="flex flex-row justify-around mt-4">
      <Button
        content={ButtonType.Previous}
        onClick={() => dispatch({ kind: "skipBackward" })}
      />
      <Button
        content={ButtonType.Next}
        onClick={() => dispatch({ kind: "skipForward" })}
      />
    </div>
  );
}
