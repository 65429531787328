import React from "react";
import { maxTries, State, useGameState } from "../GameStateContext";
import { getInputtedWord } from "../getInputtedWord";
import {
  CorrectFeedbackGroup,
  FinalIncorrectFeedbackGroup,
  IncorrectFeedbackGroup,
  TimeExpiredFeedbackGroup,
} from "./FeedbackGroup";

export default function Feedback() {
  const gameState = useGameState();
  const word =
    gameState.scrambled_original_word_pairs[gameState.currentWordIndex]
      .original;
  const inputtedWord = getInputtedWord(
    gameState.currentSelectedSquares,
    gameState.scrambled_original_word_pairs[gameState.currentWordIndex]
      .scrambled
  );

  const isNoTimeLeft = gameState.timeLeft === 0;

  let feedbackGroup = null;
  if (gameState.currentState === State.CorrectAnswer) {
    feedbackGroup = <CorrectFeedbackGroup word={inputtedWord} />;
  } else if (gameState.currentState === State.IncorrectAnswer) {
    if (
      gameState.currentWordTries[gameState.currentWordIndex] < maxTries &&
      !isNoTimeLeft
    ) {
      feedbackGroup = <IncorrectFeedbackGroup />;
    } else if (
      gameState.currentWordTries[gameState.currentWordIndex] === maxTries
    ) {
      feedbackGroup = <FinalIncorrectFeedbackGroup word={word} />;
    } else if (isNoTimeLeft) {
      feedbackGroup = <TimeExpiredFeedbackGroup word={word} />;
    }
  }

  return <>{feedbackGroup}</>;
}
