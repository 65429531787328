import React from "react";

type ButtonProps = {
  content: ButtonType;
  onClick: () => void;
};

export enum ButtonType {
  Next,
  Previous,
  Instructions,
  Close,
}

const svgClassName = "block m-auto";

const nextSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="28px"
    fill="#000000"
    className={svgClassName}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z" />
  </svg>
);

const previousSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="28px"
    fill="#000000"
    className={svgClassName}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z" />
  </svg>
);

const instructionsSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
    width="24px"
    fill="#000000"
    className={svgClassName}
  >
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <path d="M11.07,12.85c0.77-1.39,2.25-2.21,3.11-3.44c0.91-1.29,0.4-3.7-2.18-3.7c-1.69,0-2.52,1.28-2.87,2.34L6.54,6.96 C7.25,4.83,9.18,3,11.99,3c2.35,0,3.96,1.07,4.78,2.41c0.7,1.15,1.11,3.3,0.03,4.9c-1.2,1.77-2.35,2.31-2.97,3.45 c-0.25,0.46-0.35,0.76-0.35,2.24h-2.89C10.58,15.22,10.46,13.95,11.07,12.85z M14,20c0,1.1-0.9,2-2,2s-2-0.9-2-2c0-1.1,0.9-2,2-2 S14,18.9,14,20z" />
    </g>
  </svg>
);

const closeSVG = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="24px"
    fill="#000000"
    className={svgClassName}
  >
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
  </svg>
);

export const buttonSizeClasses = "h-8 w-8";

export default function Button({ content, onClick }: ButtonProps) {
  let buttonContent;

  switch (content) {
    case ButtonType.Next:
      buttonContent = nextSVG;
      break;
    case ButtonType.Previous:
      buttonContent = previousSVG;
      break;
    case ButtonType.Instructions:
      buttonContent = instructionsSVG;
      break;
    case ButtonType.Close:
      buttonContent = closeSVG;
      break;
  }
  return (
    <button
      className={`border-2 border-black rounded-full ${buttonSizeClasses}`}
      onClick={onClick}
    >
      {buttonContent}
    </button>
  );
}
