import React from "react";
import { useEffect, useState } from "react";
import FeedbackInstance from "../Feedback/FeedbackInstance";
import { Answer, STARTING_TIME, useGameState } from "../GameStateContext";
import Modal from "../Modal";
import ScoreBar from "../ScoreBar";
import Timer, { formatSecondsToTimeString } from "../Timer";
import { INDEX } from "../words";
import Credits from "./Credits";
import StatisticsRow from "./StatisticsRow";
import WordsDisplay from "./WordsDisplay";

export const WORD_SPIRAL_STATISTICS_KEY = "word-spiral-statistics";

export const answerToEmoji = (answer: Answer) => {
  switch (answer) {
    case Answer.Correct:
      return "🟢";
    case Answer.Incorrect:
      return "🔴";
    case Answer.Skipped:
      return "⚫️";
    case Answer.Unanswered:
      return "⚪️";
  }
};

const copyToClipboard = (
  answers: Answer[],
  timeTaken: number,
  day: number,
  afterCopied: () => void
) => {
  const emojis = answers.map((answer) => answerToEmoji(answer)).join("");

  const shareText = `Word Spiral ${day}: ${timeTaken}s\n${emojis}`;
  const shareData = {
    title: "Word Spiral",
    text: shareText,
    url: "https://justincaustin.com/word-spiral",
  };
  // @ts-ignore
  if (navigator.canShare(shareData)) {
    navigator.share(shareData);
  }
  navigator.clipboard.writeText(
    `${shareText}\nhttps://justincaustin.com/word-spiral`
  );
  afterCopied();
};

export enum StorageVersion {
  V1 = 1,
}

export type RawGameStatistics = {
  answers: Answer[];
  timeTaken: number;
  dayNumber: number;
  storageVersion: StorageVersion;
};

type GameStatistics = {
  gamesPlayed: number;
  winPercentage: number;
  currentStreak: number;
  maxStreak: number;
  wordsCompleted: number;
  skips: number;
  avgTimePerWord: number | ":(";
  timeTaken: number;
};

const isGameWon = (answers: Answer[]): boolean => {
  return answers.every((answer) => answer === Answer.Correct);
};

const calculateGameStatistics = (
  answers: Answer[],
  timeTaken: number,
  historicalStatistics: RawGameStatistics[]
): GameStatistics => {
  const gamesPlayed = historicalStatistics.length + 1;
  let totalWins = historicalStatistics.reduce((acc, gameStatistics) => {
    const isCurrentGameWon = isGameWon(gameStatistics.answers);
    return isCurrentGameWon ? acc + 1 : acc;
  }, 0);
  if (isGameWon(answers)) {
    totalWins++;
  }
  const winPercentage = Math.round((totalWins / gamesPlayed) * 100);

  let currentStreak;
  if (isGameWon(answers)) {
    currentStreak = 1;
    for (let i = historicalStatistics.length - 1; i >= 0; i--) {
      const currentStatistics = historicalStatistics[i];
      if (isGameWon(currentStatistics.answers)) {
        currentStreak++;
      } else {
        break;
      }
    }
  } else {
    currentStreak = 0;
  }

  let maxStreak = 0;
  let currentStreakInQuestion = 0;
  for (const currentStatistics of historicalStatistics) {
    if (isGameWon(currentStatistics.answers)) {
      currentStreakInQuestion++;
    } else {
      maxStreak = Math.max(maxStreak, currentStreakInQuestion);
      currentStreakInQuestion = 0;
    }
  }

  const wordsCompleted = answers.filter(
    (answer) => answer === Answer.Correct
  ).length;
  const skips = answers.filter((answer) => answer === Answer.Skipped).length;
  const avgTimePerWord =
    wordsCompleted > 0 ? Math.floor(timeTaken / wordsCompleted) : ":(";

  return {
    gamesPlayed,
    winPercentage,
    currentStreak,
    maxStreak,
    wordsCompleted,
    skips,
    avgTimePerWord,
    timeTaken,
  };
};

export function hasUserAlreadyPlayedToday(
  historicalStatistics: RawGameStatistics[]
): boolean {
  return historicalStatistics.some(
    (gameStatistics) => gameStatistics.dayNumber === INDEX
  );
}

export default function EndGameScreen() {
  const gameState = useGameState();

  const [showShareButton, setShowShareButton] = useState(true);
  const [gameStatistics, setGameStatistics] = useState<GameStatistics | null>(
    null
  );

  const currentDate = new Date();
  const secondsSinceMidnight = Math.floor(
    (currentDate.getTime() - currentDate.setHours(0, 0, 0, 0)) / 1000
  );
  const [timeUntilNewWords, setTimeUntilNewWords] = useState(
    86400 - secondsSinceMidnight
  );

  const timeTaken =
    gameStatistics === null
      ? STARTING_TIME - gameState.timeLeft
      : gameStatistics.timeTaken;

  useEffect(() => {
    if (!showShareButton) {
      setTimeout(() => setShowShareButton(true), 1000);
    }
  }, [showShareButton]);

  // TODO: Make below a custom hook.
  useEffect(() => {
    const rawHistoricalStatistics: RawGameStatistics[] = JSON.parse(
      localStorage.getItem(WORD_SPIRAL_STATISTICS_KEY) || "[]"
    );

    const userAlreadyPlayedToday = hasUserAlreadyPlayedToday(
      rawHistoricalStatistics
    );

    let gameStatistics;
    if (userAlreadyPlayedToday) {
      const historicalStatistics = rawHistoricalStatistics.filter(
        (stats) => stats.dayNumber !== INDEX
      );
      const todaysStatistics = rawHistoricalStatistics.find(
        (stats) => stats.dayNumber === INDEX
      );
      gameStatistics = calculateGameStatistics(
        todaysStatistics!.answers,
        todaysStatistics!.timeTaken,
        historicalStatistics
      );
    } else {
      gameStatistics = calculateGameStatistics(
        gameState.answers,
        timeTaken,
        rawHistoricalStatistics
      );
    }
    setGameStatistics(gameStatistics);

    if (!userAlreadyPlayedToday) {
      localStorage.setItem(
        WORD_SPIRAL_STATISTICS_KEY,
        JSON.stringify([
          ...rawHistoricalStatistics,
          {
            answers: gameState.answers,
            timeTaken: timeTaken,
            dayNumber: INDEX,
            storageVersion: StorageVersion.V1,
          },
        ])
      );
    }
  }, [gameState.answers, timeTaken]);

  let statisticsScreen = <p>Loading statistics...</p>;
  if (gameStatistics !== null) {
    const showPositiveMessage =
      gameStatistics.wordsCompleted >=
      gameState.scrambled_original_word_pairs.length / 2;

    statisticsScreen = (
      <>
        {showPositiveMessage && (
          <FeedbackInstance content="Great job!" colorClass="bg-green-300" />
        )}
        {!showPositiveMessage && (
          <FeedbackInstance content="a solid effort" colorClass="bg-red-300" />
        )}

        <table className="border-collapse mt-2 w-full text-lg">
          <tbody>
            <StatisticsRow
              header="played"
              data={gameStatistics.gamesPlayed.toString()}
              isBold={false}
            />
            <StatisticsRow
              header="win %"
              data={`${gameStatistics.winPercentage}%`}
              isBold={false}
            />
            <StatisticsRow
              header="current streak"
              data={gameStatistics.currentStreak.toString()}
              isBold={false}
            />
            <StatisticsRow
              header="max streak"
              data={gameStatistics.maxStreak.toString()}
              isBold={false}
            />
            <StatisticsRow
              header="words completed"
              data={gameStatistics.wordsCompleted.toString()}
              isBold={false}
            />
            <StatisticsRow
              header="skipped"
              data={gameStatistics.skips.toString()}
              isBold={false}
            />
            <StatisticsRow
              header="avg time per word"
              data={formatSecondsToTimeString(gameStatistics.avgTimePerWord)}
              isBold={false}
            />
            {/* <StatisticsRow header="total time" data="2:22" isBold={true} /> */}
          </tbody>
        </table>

        {/* <div className="flex justify-center mb-2">
          <ScoreBar />
        </div> */}

        <div className="h-8 w-3/5 pr-2 border-r border-black" />

        <div className="flex justify-between text-lg">
          <div className="pr-2 w-3/5 border-r border-black">
            <h3 className="font-sans-caps text-right">next word spiral</h3>
            <div className="align-right">
              <Timer
                timeLeft={timeUntilNewWords}
                setTimeLeft={setTimeUntilNewWords}
                onTimeUp={() => null}
                timerStopped={timeUntilNewWords === 0}
                isSmall={true}
                alignRight={true}
              />
            </div>
          </div>
          <div className="w-1/3 flex justify-left">
            {showShareButton && (
              <button
                className="bg-blue-500 text-white my-1 px-1 rounded font-sans-caps"
                onClick={() =>
                  copyToClipboard(
                    gameState.answers,
                    timeTaken,
                    gameState.day,
                    () => setShowShareButton(false)
                  )
                }
              >
                share
              </button>
            )}
            {!showShareButton && <span>Copied to clipboard!</span>}
          </div>
        </div>
        <WordsDisplay
          words={gameState.scrambled_original_word_pairs.map(
            (scrambled_original_pair) => scrambled_original_pair.original
          )}
          answers={gameState.answers}
        />
        <Credits />
      </>
    );
  }

  return (
    <Modal title="Statistics" onExit={() => null} showClose={false}>
      {statisticsScreen}
    </Modal>
  );
}
