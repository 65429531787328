import React from "react";
import { useCallback, useState } from "react";
import Board from "./Board/Board";
import ButtonRow from "./ButtonRow";
import EndGameScreen from "./EndGameScreen/EndGameScreen";
import { State, useGameState, useGameStateDispatch } from "./GameStateContext";
import Header from "./Header";
import Instructions from "./Instructions/Instructions";
import ScoreBar from "./ScoreBar";
import Timer from "./Timer";

export default function Core() {
  const gameState = useGameState();
  const dispatch = useGameStateDispatch();

  const setTimeLeft = useCallback(
    (_timeLeft: number) => {
      dispatch({ kind: "timeTick" });
    },
    [dispatch]
  );

  const onTimeUp = useCallback(() => {
    dispatch({ kind: "timeExpired" });
  }, [dispatch]);

  return (
    <div className="flex flex-col justify-between h-full max-w-md mx-auto">
      <div>
        <Header />
        <ScoreBar />
      </div>
      <Board />
      <div className="mb-1">
        <ButtonRow />

        {gameState.currentState === State.Instructions && <Instructions />}
        {gameState.currentState === State.Finished && <EndGameScreen />}
        <Timer
          timeLeft={gameState.timeLeft}
          setTimeLeft={setTimeLeft}
          onTimeUp={onTimeUp}
          timerStopped={gameState.currentState !== State.Pending}
          isSmall={false}
        />
      </div>
    </div>
  );
}
