import { Answer } from "./GameStateContext";
import React from "react";

type SingleScoreIndicatorProps = {
  answer: Answer;
  isCurrentWord: boolean;
  isWithinList?: boolean;
  verticalAlign?: boolean;
};

export default function SingleScoreIndicator({
  answer,
  isCurrentWord,
  isWithinList = true,
  verticalAlign = false,
}: SingleScoreIndicatorProps) {
  let color;
  switch (answer) {
    case Answer.Correct:
      color = "bg-green-300";
      break;
    case Answer.Incorrect:
      color = "bg-red-300";
      break;
    case Answer.Skipped:
      color = "bg-black";
      break;
    case Answer.Unanswered:
      color = "bg-gray-300";
      break;
  }
  if (isCurrentWord) {
    color = "bg-blue-300";
  }

  const circle = (
    <span
      className={`inline-block h-4 w-4 rounded-full ${color} ${
        verticalAlign ? "align-middle" : ""
      }`}
      style={{ backgroundColor: color }}
    ></span>
  );
  return isWithinList ? <li className="inline mr-1">{circle}</li> : circle;
}
