import React from "react";
import Game from "./components/Game";
import "./App.css"

function App() {
  return (
    <html lang="en">
      <head>
        <title>Word Spiral</title>
        <meta property="og:title" content="Word Spiral" />
        <meta
          name="description"
          content="Solve 9 words in 2:22. Each word is laid out randomly in a spiral, and the words increase in length as you go on."
        />
        <meta
          property="og:description"
          content="Solve 9 words in 2:22. Each word is laid out randomly in a spiral, and the words increase in length as you go on."
        />
        <meta property="og:type" content="website" />
      </head>
			
			<body>
				<Game></Game>
			</body>
    </html>
  );
}

export default App;
