import seedrandom from "seedrandom";
import dayjs from "dayjs";

// @ts-ignore
import RAW_WORDS from "./words.txt";
// @ts-ignore
import RAW_ALL_WORDS from "./all_english_words.txt";

const ALL_WORDS = RAW_WORDS.split("\n");
const WORD_LEN_TO_WORDS = ALL_WORDS.reduce(
  (acc: { [x: number]: string[] }, word: string) => {
    acc[word.length] = acc[word.length] || [];
    acc[word.length].push(word);
    return acc;
  },
  {} as { [key: number]: string[] }
);
export const ALL_WORDS_SET: Set<string> = new Set(RAW_ALL_WORDS.split("\n"));

export const INDEX = dayjs().diff(dayjs("2022-03-13"), "day");

const WORD_LENS = [4, 5, 6, 6, 7, 8, 9, 10, 11, 12];
const WORDS: string[] = WORD_LENS.map((len, i) =>
  i !== 3
    ? WORD_LEN_TO_WORDS[len][INDEX % WORD_LEN_TO_WORDS[len].length]
    : WORD_LEN_TO_WORDS[len][(INDEX + 500) % WORD_LEN_TO_WORDS[len].length]
);

const rng = seedrandom(INDEX.toString());
const shift_word = WORD_LENS.map((len) => Math.floor(rng() * len));
const should_flip_word = WORD_LENS.map(() => rng() < 0.5);

const SCRAMBLED_WORDS = WORDS.map((word, index) => {
  const start = shift_word[index];
  let scrambledWord =
    word.substring(start, word.length) + word.substring(0, start);
  if (should_flip_word[index]) {
    scrambledWord = scrambledWord.split("").reverse().join("");
  }
  return scrambledWord;
});

export const SCRAMBLED_WORD_ORIGINAL_WORD_PAIRS: {
  scrambled: string;
  original: string;
}[] = SCRAMBLED_WORDS.map((scrambledWord, index) => {
  return { scrambled: scrambledWord, original: WORDS[index] };
});
