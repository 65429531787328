import { createContext, Dispatch, useContext, useReducer } from "react";
import React from "react";
import { getInputtedWord } from "./getInputtedWord";
import {
  ALL_WORDS_SET,
  INDEX,
  SCRAMBLED_WORD_ORIGINAL_WORD_PAIRS,
} from "./words";

export const STARTING_TIME = 180;

export const maxTries = 3;

export const GameStateContext = createContext<GameState | null>(null);
export const GameStateDispatchContext = createContext<Dispatch<Action> | null>(
  null
);

type GameStateProviderProps = {
  children: React.ReactNode;
};

export function GameStateProvider({ children }: GameStateProviderProps) {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  return (
    <GameStateContext.Provider value={state}>
      <GameStateDispatchContext.Provider value={dispatch}>
        {children}
      </GameStateDispatchContext.Provider>
    </GameStateContext.Provider>
  );
}

export function useGameState() {
  const state = useContext(GameStateContext);
  if (state === null) {
    throw new Error("useGameState must be used within a GameStateProvider");
  }
  return state;
}

export function useGameStateDispatch() {
  const dispatch = useContext(GameStateDispatchContext);
  if (dispatch === null) {
    throw new Error(
      "useGameStateDispatch must be used within a GameStateProvider"
    );
  }
  return dispatch;
}

export enum Answer {
  Correct = "correct",
  Incorrect = "incorrect",
  Skipped = "skipped",
  Unanswered = "unanswered",
}

export enum State {
  Instructions = "instructions",
  Pending = "pending",
  IncorrectAnswer = "incorrectAnswer",
  CorrectAnswer = "correctAnswer",
  Finished = "finished",
}

type GameState = {
  scrambled_original_word_pairs: { scrambled: string; original: string }[];
  currentWordIndex: number;
  answers: Answer[];
  currentState: State;
  // On third wrong try, the word is marked as incorrect
  currentWordTries: number[];
  currentSelectedSquares: number[];
  day: number;
  timeLeft: number;
};

interface Instructions {
  kind: "instructions";
}

interface GoToGame {
  kind: "goToGame";
}

interface LetterSelect {
  kind: "letterSelect";
  index: number;
}

interface LetterUnselect {
  kind: "letterUnselect";
  index: number;
}

interface SkipBackward {
  kind: "skipBackward";
}

interface SkipForward {
  kind: "skipForward";
}

interface NextWord {
  kind: "nextWord";
}

interface TryAgain {
  kind: "tryAgain";
}

interface TimeTick {
  kind: "timeTick";
}

interface TimeExpired {
  kind: "timeExpired";
}

interface GameFinished {
  kind: "gameFinished";
}

interface GameAlreadyPlayedToday {
  kind: "gameAlreadyPlayedToday";
  answers: Answer[];
  timeLeft: number;
}

type Action =
  | Instructions
  | GoToGame
  | LetterSelect
  | LetterUnselect
  | SkipBackward
  | SkipForward
  | NextWord
  | TryAgain
  | TimeTick
  | TimeExpired
  | GameFinished
  | GameAlreadyPlayedToday;

const stateReducer = (state: GameState, action: Action) => {
  switch (action.kind) {
    case "instructions":
      return {
        ...state,
        currentState: State.Instructions,
      };
    case "goToGame":
      return {
        ...state,
        currentState: State.Pending,
      };
    case "letterSelect":
      const newSelectedSquares = [
        ...state.currentSelectedSquares,
        action.index,
      ];
      if (newSelectedSquares.length === 4) {
        const inputtedWord = getInputtedWord(
          newSelectedSquares,
          state.scrambled_original_word_pairs[state.currentWordIndex].scrambled
        );
        const currentWord =
          state.scrambled_original_word_pairs[state.currentWordIndex].original;
        if (currentWord === inputtedWord || ALL_WORDS_SET.has(inputtedWord)) {
          // TODO: Pass along inputtedWord to show to user in banner instead of original word.
          return {
            ...state,
            currentSelectedSquares: newSelectedSquares,
            answers: state.answers.map((answer, i) =>
              i === state.currentWordIndex ? Answer.Correct : answer
            ),
            currentState: State.CorrectAnswer,
          };
        } else {
          return {
            ...state,
            currentSelectedSquares: newSelectedSquares,
            currentWordTries: state.currentWordTries.map((tries, index) =>
              index === state.currentWordIndex ? tries + 1 : tries
            ),
            answers: state.answers.map((answer, i) =>
              i === state.currentWordIndex
                ? state.currentWordTries[state.currentWordIndex] ===
                  maxTries - 1
                  ? Answer.Incorrect
                  : answer
                : answer
            ),
            currentState: State.IncorrectAnswer,
          };
        }
      }
      return {
        ...state,
        currentSelectedSquares: newSelectedSquares,
      };
    case "letterUnselect":
      return {
        ...state,
        currentSelectedSquares: state.currentSelectedSquares.filter(
          (index) => index !== action.index
        ),
      };
    case "skipBackward":
      const nextWordIndexForSkipBackward = getNextValidWordIndex(
        state.currentWordIndex,
        state.answers,
        state.scrambled_original_word_pairs.length,
        Direction.Backward
      );
      if (nextWordIndexForSkipBackward === null) {
        throw new Error(
          "Encountered no words left when user skipped. This should never happen."
        );
      }
      return {
        ...state,
        currentInput: "",
        currentSelectedSquares: [],
        answers: state.answers.map((answer, i) =>
          i === state.currentWordIndex ? Answer.Skipped : answer
        ),
        currentWordIndex: nextWordIndexForSkipBackward,
      };
    case "skipForward":
      const nextWordIndexForSkip = getNextValidWordIndex(
        state.currentWordIndex,
        state.answers,
        state.scrambled_original_word_pairs.length,
        Direction.Forward
      );
      if (nextWordIndexForSkip === null) {
        throw new Error(
          "Encountered no words left when user skipped. This should never happen."
        );
      }
      return {
        ...state,
        currentInput: "",
        currentSelectedSquares: [],
        answers: state.answers.map((answer, i) =>
          i === state.currentWordIndex ? Answer.Skipped : answer
        ),
        currentWordIndex: nextWordIndexForSkip,
      };
    case "nextWord":
      const nextWordIndexforNextWord = getNextValidWordIndex(
        state.currentWordIndex,
        state.answers,
        state.scrambled_original_word_pairs.length,
        Direction.Forward
      );
      if (nextWordIndexforNextWord === null) {
        // No more words left.
        return {
          ...state,
          currentState: State.Finished,
        };
      }
      return {
        ...state,
        currentSelectedSquares: [],
        currentWordIndex: nextWordIndexforNextWord,
        currentState: State.Pending,
      };
    case "tryAgain":
      return {
        ...state,
        currentSelectedSquares: [],
        currentState: State.Pending,
      };
    case "timeTick":
      return {
        ...state,
        timeLeft: state.timeLeft - 1,
      };
    case "timeExpired":
      return {
        ...state,
        currentState: State.IncorrectAnswer,
      };
    case "gameFinished":
      return {
        ...state,
        currentState: State.Finished,
      };
    case "gameAlreadyPlayedToday":
      return {
        ...state,
        answers: action.answers,
        currentState: State.Finished,
      };
    default:
      throw new Error(`Unhandled action type: ${action}`);
  }
};

enum Direction {
  Forward,
  Backward,
}

const getNextValidWordIndex = (
  currentWordIndex: number,
  answers: Answer[],
  wordsLen: number,
  direction: Direction
) => {
  let nextValidIndex = currentWordIndex;
  for (let i = 0; i < answers.length; i++) {
    if (direction === Direction.Forward) {
      nextValidIndex = nextValidIndex === wordsLen - 1 ? 0 : nextValidIndex + 1;
    } else {
      nextValidIndex = nextValidIndex === 0 ? wordsLen - 1 : nextValidIndex - 1;
    }
    if (
      answers[nextValidIndex] !== Answer.Correct &&
      answers[nextValidIndex] !== Answer.Incorrect
    ) {
      return nextValidIndex;
    }
  }
  // No more words left.
  return null;
};

const initialState = {
  scrambled_original_word_pairs: SCRAMBLED_WORD_ORIGINAL_WORD_PAIRS,
  currentWordIndex: 0,
  answers: Array(10).fill(Answer.Unanswered),
  currentState: State.Instructions,
  currentWordTries: Array(10).fill(0),
  currentSelectedSquares: [],
  day: INDEX,
  timeLeft: STARTING_TIME,
};
