import React from "react";
import { useEffect, useState } from "react";
import {
  hasUserAlreadyPlayedToday,
  RawGameStatistics,
  WORD_SPIRAL_STATISTICS_KEY,
} from "../EndGameScreen/EndGameScreen";
import { STARTING_TIME, useGameStateDispatch } from "../GameStateContext";
import Modal from "../Modal";
import { INDEX } from "../words";
import FirstInstructions from "./FirstInstructions";
import FourthInstructions from "./FourthInstructions";
import SecondInstructions from "./SecondInstructions";
import ThirdInstructions from "./ThirdInstructions";

export default function Instructions() {
  const dispatch = useGameStateDispatch();
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    const historicalStatistics: RawGameStatistics[] = JSON.parse(
      localStorage.getItem(WORD_SPIRAL_STATISTICS_KEY) || "[]"
    );
    if (hasUserAlreadyPlayedToday(historicalStatistics)) {
      const todaysStatistics = historicalStatistics.find(
        (stats) => stats.dayNumber === INDEX
      )!;
      dispatch({
        kind: "gameAlreadyPlayedToday",
        timeLeft: STARTING_TIME - todaysStatistics.timeTaken,
        answers: todaysStatistics.answers,
      });
    }
  }, [dispatch]);

  return (
    <Modal
      title="Instructions"
      onExit={() => dispatch({ kind: "goToGame" })}
      showClose={true}
    >
      {pageNumber === 0 && (
        <FirstInstructions onNext={() => setPageNumber(1)} />
      )}
      {pageNumber === 1 && (
        <SecondInstructions
          onPrevious={() => setPageNumber(0)}
          onNext={() => setPageNumber(2)}
        />
      )}
      {pageNumber === 2 && (
        <ThirdInstructions
          onPrevious={() => setPageNumber(1)}
          onNext={() => setPageNumber(3)}
        />
      )}
      {pageNumber === 3 && (
        <FourthInstructions onPrevious={() => setPageNumber(2)} />
      )}
    </Modal>
  );
}
