import React from "react";
import { getSquareClassNames } from "./Square";

type SquareProps = {
  letter: string;
  isSmall: boolean;
  isClicked: boolean;
  onClick: () => void;
};

export default function RawSquare({
  letter,
  isSmall,
  isClicked,
  onClick,
}: SquareProps) {
  const squareClassNames = getSquareClassNames(isSmall);

  const background = isClicked ? "bg-gray-600" : "bg-gray-200";
  const textColor = isClicked ? "text-white" : "";

  return (
    <button
      className={`rounded border border-black ${squareClassNames} ${background} ${textColor}`}
      onClick={onClick}
    >
      {letter}
    </button>
  );
}
