export const getInputtedWord = (
  selectedIndices: number[],
  scrambledWord: string
): string => {
  let areSelectedIndicesIncreasing;
  if (selectedIndices[0] === 0) {
    areSelectedIndicesIncreasing =
      selectedIndices[1] !== scrambledWord.length - 1;
  } else if (selectedIndices[0] === scrambledWord.length - 1) {
    areSelectedIndicesIncreasing = selectedIndices[1] === 0;
  } else {
    areSelectedIndicesIncreasing = selectedIndices[0] < selectedIndices[1];
  }

  let inputtedWord = "";
  let i = selectedIndices[0];
  while (inputtedWord.length < scrambledWord.length) {
    inputtedWord += scrambledWord[i];
    if (areSelectedIndicesIncreasing) {
      i = i === scrambledWord.length - 1 ? 0 : i + 1;
    } else {
      i = i === 0 ? scrambledWord.length - 1 : i - 1;
    }
  }
  return inputtedWord;
};
