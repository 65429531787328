import React from "react";
import { useEffect } from "react";
import { useGameStateDispatch } from "../GameStateContext";
import FeedbackInstance from "./FeedbackInstance";

const correctMessages = [
  "Yee haw!",
  "En Fuego!",
  "Jolly Good!",
  "Ka-Ching!",
  "Wowza!",
  "Super Sauce!",
  "Awesome Sauce!",
  "Great Scott!",
  "Cowabunga!",
  "Nailed It!",
  "Great Balls of Fire!",
  "OMG!",
  "Wowser!",
  "Shut the Front Door!",
  "Yesssssss!",
  "Holy Moley!",
  "Holy Moley Guacamole!",
  "Kaboom!",
  "Boom!",
  "Fantastico!",
  "Ooh-La-La!",
  "Tres Bien!",
  "Wonderful!",
  "Wicked Smart!",
  "Yippee!",
];

const feedbackDuration = 1000;

type FeedbackGroupProps = {
  children: React.ReactNode;
};

function FeedbackGroup({ children }: FeedbackGroupProps) {
  return (
    <div className="absolute flex flex-col justify-center gap-2 h-full w-3/4 inset-x-0 top-0 mx-auto">
      {children}
    </div>
  );
}

export function IncorrectFeedbackGroup() {
  const dispatch = useGameStateDispatch();

  useEffect(() => {
    const timer = setTimeout(
      () => dispatch({ kind: "tryAgain" }),
      feedbackDuration
    );

    return () => clearTimeout(timer);
  });

  return (
    <FeedbackGroup>
      <FeedbackInstance content="incorrect" colorClass="bg-red-300" />
    </FeedbackGroup>
  );
}

export function FinalIncorrectFeedbackGroup({ word }: { word: string }) {
  const dispatch = useGameStateDispatch();

  useEffect(() => {
    const timer = setTimeout(
      () => dispatch({ kind: "nextWord" }),
      feedbackDuration * 2
    );

    return () => clearTimeout(timer);
  });

  return (
    <FeedbackGroup>
      <FeedbackInstance content="incorrect" colorClass="bg-red-300" />
      <FeedbackInstance content={word} colorClass="bg-red-300" />
    </FeedbackGroup>
  );
}

export function CorrectFeedbackGroup({ word }: { word: string }) {
  const dispatch = useGameStateDispatch();

  useEffect(() => {
    const timer = setTimeout(
      () => dispatch({ kind: "nextWord" }),
      feedbackDuration
    );

    return () => clearTimeout(timer);
  });

  return (
    <FeedbackGroup>
      <FeedbackInstance
        content={
          correctMessages[Math.floor(Math.random() * correctMessages.length)]
        }
        colorClass="bg-green-300"
      />
      <FeedbackInstance content={word} colorClass="bg-green-300" />
    </FeedbackGroup>
  );
}

export function TimeExpiredFeedbackGroup({ word }: { word: string }) {
  const dispatch = useGameStateDispatch();

  useEffect(() => {
    const timer = setTimeout(
      () => dispatch({ kind: "gameFinished" }),
      feedbackDuration * 2
    );

    return () => clearTimeout(timer);
  });

  return (
    <FeedbackGroup>
      <FeedbackInstance content="time expired" colorClass="bg-gray-300" />
      <FeedbackInstance content={word} colorClass="bg-gray-300" />
    </FeedbackGroup>
  );
}
