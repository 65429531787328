import React from "react";
import Core from "./Core";
import { GameStateProvider } from "./GameStateContext";

export default function WordSpiralGame() {
  return (
    <GameStateProvider>
      <Core />
    </GameStateProvider>
  );
}
