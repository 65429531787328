import React from "react";
import Button, { buttonSizeClasses, ButtonType } from "../Button";
import InstructionsGridTen from "./InstructionsGridTen";

type FirstInstructionsProps = {
  onNext: () => void;
};

export default function FirstInstructions({ onNext }: FirstInstructionsProps) {
  return (
    <>
      <p>
        Determine 10 words in 3:00. Words are clockwise or counter-clockwise and
        can start at any position. The word will always use all of the letters.
      </p>
      <InstructionsGridTen
        word="IMPOSSIBLE"
        startingIndex={0}
        clockwise={true}
      />
      <p>
        This word is <span className="font-sans-caps">IMPOSSIBLE</span>. To
        input this word, you would tap on the first 4 letters of the word in
        order, as shown here.
      </p>
      <div className="flex justify-between">
        <span className={buttonSizeClasses} />
        <span>1/4</span>
        <Button content={ButtonType.Next} onClick={onNext} />
      </div>
    </>
  );
}
