import Button, { ButtonType } from "./Button";
import { useGameStateDispatch } from "./GameStateContext";
import React from "react";

export default function Header() {
  const dispatch = useGameStateDispatch();

  return (
    <div className="border-b border-black flex justify-between mx-1 py-1">
      <Button
        content={ButtonType.Instructions}
        onClick={() => dispatch({ kind: "instructions" })}
      />
      <h1 className="text-center self-center font-sans-caps lowercase text-xl">
        Word Spiral
      </h1>
      <div className="h-8 w-8"></div>
    </div>
  );
}
