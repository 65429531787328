import React from "react";
import Button, { buttonSizeClasses, ButtonType } from "../Button";
import InstructionsGridTen from "./InstructionsGridTen";

type Props = {
  onPrevious: () => void;
};

export default function FourthInstructions({ onPrevious }: Props) {
  return (
    <>
      <p>
        Nice! If you&apos;re running low on time, feel free to guess. You get 3
        tries per word!
      </p>
      <p className="mt-4">
        If you don&apos;t know the word, you can skip it using the skip buttons:{" "}
        {<Button content={ButtonType.Previous} onClick={() => null} />}{" "}
        {<Button content={ButtonType.Next} onClick={() => null} />}
      </p>
      <p className="mt-4">
        Ready to play? Your time will start when you click the close button
        above.
      </p>
      <div className="flex justify-between mt-2">
        <Button content={ButtonType.Previous} onClick={onPrevious} />
        <span>4/4</span>
        <span className={buttonSizeClasses} />
      </div>
    </>
  );
}
