import React from "react";
import Square, { BlankSquare } from "./Square";

export default function GridFive({ word }: { word: string }) {
  if (word.length !== 5) {
    throw new Error("GridFive only works with words of length 5");
  }
  return (
    <div className="w-fit m-auto">
      <div className="flex justify-center">
        <Square word={word} index={0} />
        <Square word={word} index={1} />
      </div>
      <div className="flex justify-center">
        <Square word={word} index={4} />
        <BlankSquare />
        <Square word={word} index={2} />
      </div>
      <div className="flex justify-center">
        <Square word={word} index={3} />
      </div>
    </div>
  );
}
