import React from "react";
import { useEffect, useState } from "react";
import RawSquare from "../Board/RawSquare";
import { BlankSquare } from "../Board/Square";

type Props = {
  word: string;
  startingIndex: number;
  clockwise: boolean;
};

export default function InstructionsGridTen({
  word,
  startingIndex,
  clockwise,
}: Props): JSX.Element {
  const [squaresClicked, setSquaresClicked] = useState<
    [boolean, boolean, boolean, boolean]
  >([false, false, false, false]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSquaresClicked((prevSquaresClicked) => {
        switch (prevSquaresClicked.filter((clicked) => clicked).length) {
          case 0:
            return [true, false, false, false];
          case 1:
            return [true, true, false, false];
          case 2:
            return [true, true, true, false];
          case 3:
            return [true, true, true, true];
          default:
            return [false, false, false, false];
        }
      });
      return () => clearTimeout(timer);
    }, 1000);
  }, [squaresClicked]);

  let indicesToClick = [startingIndex];
  let currentIndex = startingIndex;
  for (let i = 0; i < 3; i++) {
    if (clockwise) {
      currentIndex = (currentIndex + 1) % word.length;
    } else {
      currentIndex = (currentIndex - 1 + word.length) % word.length;
    }
    indicesToClick.push(currentIndex);
  }

  return (
    <div className="w-fit m-auto">
      <div className="flex justify-center">
        <RawSquare
          letter={word[0]}
          isSmall={false}
          isClicked={
            indicesToClick.includes(0)
              ? squaresClicked[indicesToClick.indexOf(0)]
              : false
          }
          onClick={() => null}
        />
        <RawSquare
          letter={word[1]}
          isSmall={false}
          isClicked={
            indicesToClick.includes(1)
              ? squaresClicked[indicesToClick.indexOf(1)]
              : false
          }
          onClick={() => null}
        />
        <RawSquare
          letter={word[2]}
          isSmall={false}
          isClicked={
            indicesToClick.includes(2)
              ? squaresClicked[indicesToClick.indexOf(2)]
              : false
          }
          onClick={() => null}
        />
      </div>
      <div className="flex justify-center">
        <RawSquare
          letter={word[9]}
          isSmall={false}
          isClicked={
            indicesToClick.includes(9)
              ? squaresClicked[indicesToClick.indexOf(9)]
              : false
          }
          onClick={() => null}
        />
        <BlankSquare />
        <RawSquare
          letter={word[3]}
          isSmall={false}
          isClicked={
            indicesToClick.includes(3)
              ? squaresClicked[indicesToClick.indexOf(3)]
              : false
          }
          onClick={() => null}
        />
      </div>
      <div className="flex justify-center">
        <RawSquare
          letter={word[8]}
          isSmall={false}
          isClicked={
            indicesToClick.includes(8)
              ? squaresClicked[indicesToClick.indexOf(8)]
              : false
          }
          onClick={() => null}
        />
        <BlankSquare />
        <RawSquare
          letter={word[4]}
          isSmall={false}
          isClicked={
            indicesToClick.includes(4)
              ? squaresClicked[indicesToClick.indexOf(4)]
              : false
          }
          onClick={() => null}
        />
      </div>
      <div className="flex justify-center">
        <RawSquare
          letter={word[7]}
          isSmall={false}
          isClicked={
            indicesToClick.includes(7)
              ? squaresClicked[indicesToClick.indexOf(7)]
              : false
          }
          onClick={() => null}
        />
        <RawSquare
          letter={word[6]}
          isSmall={false}
          isClicked={
            indicesToClick.includes(6)
              ? squaresClicked[indicesToClick.indexOf(6)]
              : false
          }
          onClick={() => null}
        />
        <RawSquare
          letter={word[5]}
          isSmall={false}
          isClicked={
            indicesToClick.includes(5)
              ? squaresClicked[indicesToClick.indexOf(5)]
              : false
          }
          onClick={() => null}
        />
      </div>
    </div>
  );
}
