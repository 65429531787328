import { useEffect } from "react";
import React from "react";

type TimerProps = {
  timeLeft: number;
  setTimeLeft: (timeLeft: number) => void;
  onTimeUp: () => void;
  timerStopped: boolean;
  isSmall: boolean;
  alignRight?: boolean;
};

export default function Timer({
  timeLeft,
  setTimeLeft,
  onTimeUp,
  timerStopped,
  isSmall,
  alignRight = false,
}: TimerProps) {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!timerStopped) {
        if (timeLeft === 0) {
          onTimeUp();
        } else {
          setTimeLeft(timeLeft - 1);
        }
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft, onTimeUp, setTimeLeft, timerStopped]);

  let classNames = isSmall ? "my-1 text-lg" : "my-4 text-3xl";

  if (alignRight) {
    classNames += " text-right";
  } else {
    classNames += " mx auto text-center";
  }

  if (timeLeft <= 30) {
    classNames += " text-red-500";
  }

  return (
    <div className={classNames}>{formatSecondsToTimeString(timeLeft)}</div>
  );
}

export function formatSecondsToTimeString(seconds: number | ":(") {
  if (typeof seconds !== "number") {
    return seconds;
  }
  const hours = Math.floor(seconds / 3600);
  if (hours === 0) {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes}:${secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}`;
  } else {
    const minutesLeft = seconds % 3600;
    const minutes = Math.floor(minutesLeft / 60);
    const secondsLeft = minutesLeft % 60;
    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
      secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft
    }`;
  }
}
