import React from "react";
import { useGameState, useGameStateDispatch } from "../GameStateContext";
import RawSquare from "./RawSquare";

type SquareProps = {
  word: string;
  index: number;
};

export const getSquareClassNames = (isSmall: boolean): string => {
  return isSmall ? "h-10 w-10 m-1" : "h-12 w-12 m-1";
};

export default function Square({ word, index }: SquareProps) {
  const gameState = useGameState();
  const dispatch = useGameStateDispatch();

  const letter = word[index];
  const isClicked = gameState.currentSelectedSquares.includes(index);

  const onClick = () => {
    if (isClicked) {
      dispatch({ kind: "letterUnselect", index });
    } else {
      dispatch({ kind: "letterSelect", index });
    }
  };

  return (
    <RawSquare
      letter={letter}
      isSmall={false}
      isClicked={isClicked}
      onClick={onClick}
    />
  );
}

export function BlankSquare() {
  const squareClassNames = getSquareClassNames(false);
  return <div className={`${squareClassNames}`} />;
}
