import React from "react";
import Square from "./Square";

export default function GridFour({ word }: { word: string }) {
  if (word.length !== 4) {
    throw new Error("GridFour only works with words of length 4");
  }
  return (
    <div className="grid grid-rows-2 grid-cols-2 justify-items-center w-fit m-auto">
      <Square word={word} index={0} />
      <Square word={word} index={1} />
      <Square word={word} index={3} />
      <Square word={word} index={2} />
    </div>
  );
}
